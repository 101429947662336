import { Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import { Province, Region } from '../interfaces/regions.interface';
import { IndexingService } from '../services/indexing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormRadiobuttonService } from 'src/app/shared/form-radiobutton/form-radiobutton.service';


@Component({
  selector: 'ds-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit, OnDestroy {
  dataRegions: any[] = [];
  selectedRegions: Region[] = [];
  allRegionsSelected = false;
  private onDestroy$ = new Subject<void>();
  private _region: any;
  @Input() set region(value: any) {
    this._region = value;
  }

  get region(): any {
    return this._region;
  }

  protected data_from_dspace: any;

  @Output() notifyCountry = new EventEmitter<Region[]>();

  @Input() submissionId: number;

  constructor(private service: IndexingService,
    protected formRadiobuttonService: FormRadiobuttonService) {
    this.service.getRegionsGlobal()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.onselectAllRegionChange(data);
    });
    this.service.getSearchPlace()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dynamicRegionChange(data);
    });
  }

  ngOnInit(): void {

    
    
    this.data_from_dspace = this.formRadiobuttonService.getCoverageData(this.submissionId);

    this.dataRegions = this.region.map(it => {
      const nuevoItem = {
        checked: false,
        disabled: false
      };
      this.data_from_dspace.forEach((item) => {
        for (const clave in item) {
          if (item.hasOwnProperty(clave)) {
            const valor = item[clave];
            //console.log(valor.regiones)
            if(valor.regiones != undefined) {
              for (let i = 0; i < valor.regiones.length; i++) {
                const element = valor.regiones[i];
                if(it.nombre == element) {
                  nuevoItem.checked = true;
                }
                
              }
            }          
          }
        }
      });

      for (const clave in it) {
        if (it.hasOwnProperty(clave)) {
          nuevoItem[clave] = it[clave];
          
        }
      }
    
      return nuevoItem;
    });
    

  }

  onHandleChangeProvince(province: Province[] , index: number) {
    this.dataRegions[index].checked = true;
    this.dataRegions[index].disabled = province.length > 0 ? true : false;
    const existingRegions = this.selectedRegions.find(r => r.id === this.dataRegions[index].id);
    if (!existingRegions) {
      this.selectedRegions.push({
        id: this.dataRegions[index].id,
        name: this.dataRegions[index].nombre,
        provinces: !Array.isArray(province) ? [province] : province
      });
    } else {
      const provinceToUpdate = this.selectedRegions.find(r => r.id === existingRegions.id);
      if (provinceToUpdate) {
        provinceToUpdate.provinces = !Array.isArray(province) ? [province] : province;
      }
    }
    this.notifyCountry.emit(this.selectedRegions);
  }

  handleChangeRegion(event: any, index: number) {
    this.dataRegions[index].checked = event.target.checked;
    if (this.dataRegions[index].checked) {
      const region = this.selectedRegions.find(r => r.id === this.dataRegions[index].id);
      if (!region) {
        this.selectedRegions.push({
          id: this.dataRegions[index].id,
          name: this.dataRegions[index].nombre,
          provinces: []
        });
      }
    } else {
      const itemOfRegion = this.selectedRegions.findIndex((region) => region.id === this.dataRegions[index].id);
      if (itemOfRegion !== -1) {
        this.selectedRegions.splice(itemOfRegion, 1);
      }
    }
    this.notifyCountry.emit(this.selectedRegions);
  }

  onselectAllRegionChange(value: boolean) {
    if  (value) {
      this.dataRegions.forEach((region, index) => {
          region.checked = true;
          const existingRegion = this.selectedRegions.find(r => r.id === region.id);
          if (!existingRegion) {
            this.selectedRegions.push({
              id: this.dataRegions[index].id,
              name: this.dataRegions[index].nombre,
              provinces: []
            });
          }
      });
    } else {
      this.selectedRegions = this.selectedRegions.filter(region => {
        const hasEmptyProvinces = Array.isArray(region.provinces) && region.provinces.length === 0;
        return !hasEmptyProvinces;
      });
      this.dataRegions.forEach(region => {
        if (!region.disabled) {
          region.checked = false;
        }
      });
    }
    this.notifyCountry.emit(this.selectedRegions);
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  dynamicRegionChange(data) {
    if (data !== null){
      if (data.id !== null){
        const matchingElement = this.dataRegions.find(d => d.id === data.region.id);
        if (matchingElement){
          matchingElement.checked = true;
          this.selectedRegions.push({
            id: matchingElement.id,
            name: matchingElement.nombre,
            provinces: []
          });
          this.notifyCountry.emit(this.selectedRegions);
        }
      }
    }
  }
}
