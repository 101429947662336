<div class="row" *ngIf="province">
  <div class="col-12 mb-1">
    <h4 class="lh-1 mb-2">Provincias</h4>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="allp{{region.id}}" [checked]="allProvinceSelected.checked" [disabled]="allProvinceSelected.disabled" (change)="checkAllProvinces($event.target.checked)">
      <label class="custom-control-label" for="allp{{region.id}}">Todas las provincias de esta región</label>
      <hr class="mt-2"/>
    </div>
  </div>
  <div class="col-12 mb-3" *ngFor="let province of dataProvinces; let i = index">
    <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
      <ngb-panel id="custom-panel-0">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="accordion-button custom-header justify-content-between" [class.collapsed]="!opened">
            <button ngbPanelToggle class="btn-header-accordion">
              <i class="fas fa-chevron-down"></i>
              <span>{{province.nombre}}</span>
            </button>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="{{province.id}}" [checked]="province.checked" [disabled]="province.disabled" (change)="checkProvince($event, i)">
              <label class="custom-control-label" for="{{province.id}}">Seleccionar</label>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ds-commune [data_from_dspace]="data_from_dspace" [region]="region" [province]="province" [commune]="province.comuna" (listenCommunes)="onHandleChangeCommune($event, i)"></ds-commune>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>

