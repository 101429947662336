import { Component, ElementRef, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { IndexingService } from '../services/indexing.service';

@Component({
  selector: 'ds-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  options: any[] = [];
  filteredOptions: any[] = [];
  selectedOption: any;
  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;
  @Output() optionSelected = new EventEmitter<any>();
  constructor(private service: IndexingService) {}

  ngOnInit(): void {
    this.service.getDataRegions().subscribe(data => {
      this.options = data;
    });
  }
  onInput(search: string){
    if (!search) {
      this.filteredOptions = [];
      return;
    }
    this.filteredOptions = this.filterOptions(search);
  }

  onOptionSelected(option: any) {
    const {id, name, provincia, comuna} = option;
    this.filteredOptions = [];
    this.inputRef.nativeElement.value = name;

    const region = this.options.find(r => r.id === id);
    const province = region?.provincia?.find(p => p.nombre === provincia) || null;
    const regionName = region?.nombre || null;
    const provinceName = province?.nombre || null;

    const place = {
      region: {id, name: regionName},
      province: {id: province?.id, name: provinceName, comuna: comuna || null},
      comuna: comuna || null
    };

    this.service.setSearchPlace(place);
    this.inputRef.nativeElement.value = '';
  }


  filterOptions(value: string) {
    return this.options.reduce((filtered: any[], region: any) => {
      if (region.nombre.toLowerCase().includes(value.toLowerCase())) {
        filtered.push({id: region.id, name: region.nombre, region: null, provincia: null, comuna: null });
      }
      region.provincia.forEach(provincia => {
        if (provincia.nombre.toLowerCase().includes(value.toLowerCase())) {
          filtered.push({id: region.id, name: `Provincia de ${provincia.nombre}`, region: region.nombre, provincia: provincia.nombre, comuna: null });
        }
        provincia.comuna.forEach(comuna => {
          if (comuna.nombre.toLowerCase().includes(value.toLowerCase())) {
            filtered.push({ name: `Comuna de ${comuna.nombre}`, region: region.nombre, provincia: provincia.nombre, comuna: comuna.nombre });
          }
        });
      });
      return filtered;
    }, []).filter(option => option.name.toLowerCase().includes(value.toLowerCase()));
  }


}
