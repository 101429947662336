<div class="facet-filter d-block py-2" *ngIf="active$ | async"
     [id]="regionId" [attr.aria-labelledby]="toggleId" [ngClass]="{ 'focus': focusBox }" role="region">
  <button (click)="toggle()" (focusin)="focusBox = true" (focusout)="focusBox = false"
          class="filter-name d-flex" [attr.aria-controls]="regionId" [id]="toggleId"
          [attr.aria-expanded]="false"
          [attr.aria-label]="(((collapsed$ | async) ? 'search.filters.filter.expand' : 'search.filters.filter.collapse') | translate) + ' ' + (('search.filters.filter.' + filter.name + '.head') | translate | lowercase)"
          [attr.data-test]="'filter-toggle' | dsBrowserOnly"
  >
    <h5 class="d-inline-block mb-0">
      {{'search.filters.filter.' + filter.name + '.head'| translate}}
    </h5>
    <span class="filter-toggle flex-grow-1 fas p-auto"
          [title]="((collapsed$ | async) ? 'search.filters.filter.expand' : 'search.filters.filter.collapse') | translate">
          <svg *ngIf="(collapsed$ | async)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.16675 8.16666V4.83332H9.83342V8.16666H13.1667V9.83332H9.83342V13.1667H8.16675V9.83332H4.83342V8.16666H8.16675ZM9.00008 17.3333C4.39758 17.3333 0.666748 13.6025 0.666748 8.99999C0.666748 4.39749 4.39758 0.666656 9.00008 0.666656C13.6026 0.666656 17.3334 4.39749 17.3334 8.99999C17.3334 13.6025 13.6026 17.3333 9.00008 17.3333ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9643 13.7141 13.714C14.9644 12.4638 15.6667 10.7681 15.6667 8.99999C15.6667 7.23188 14.9644 5.53619 13.7141 4.28594C12.4639 3.0357 10.7682 2.33332 9.00008 2.33332C7.23197 2.33332 5.53628 3.0357 4.28604 4.28594C3.03579 5.53619 2.33341 7.23188 2.33341 8.99999C2.33341 10.7681 3.03579 12.4638 4.28604 13.714C5.53628 14.9643 7.23197 15.6667 9.00008 15.6667Z" fill="currentColor"/>
          </svg>
          <svg *ngIf="!(collapsed$ | async)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00008 17.3333C4.39758 17.3333 0.666748 13.6025 0.666748 8.99999C0.666748 4.39749 4.39758 0.666656 9.00008 0.666656C13.6026 0.666656 17.3334 4.39749 17.3334 8.99999C17.3334 13.6025 13.6026 17.3333 9.00008 17.3333ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9643 13.7141 13.714C14.9644 12.4638 15.6667 10.7681 15.6667 8.99999C15.6667 7.23188 14.9644 5.53619 13.7141 4.28594C12.4639 3.0357 10.7682 2.33332 9.00008 2.33332C7.23197 2.33332 5.53628 3.0357 4.28604 4.28594C3.03579 5.53619 2.33341 7.23188 2.33341 8.99999C2.33341 10.7681 3.03579 12.4638 4.28604 13.714C5.53628 14.9643 7.23197 15.6667 9.00008 15.6667ZM4.83342 8.16666H13.1667V9.83332H4.83342V8.16666Z" fill="currentColor"/>
          </svg>

    </span>
  </button>
  <div [@slide]="(collapsed$ | async) ? 'collapsed' : 'expanded'"
       (@slide.start)="startSlide($event)" (@slide.done)="finishSlide($event)"
       class="search-filter-wrapper" [ngClass]="{ 'closed' : closed, 'notab': notab }">
    <ds-search-facet-filter-wrapper
      [filterConfig]="filter"
      [inPlaceSearch]="inPlaceSearch"
      [refreshFilters]="refreshFilters" >
    </ds-search-facet-filter-wrapper>
  </div>
</div>
