<nav [ngClass]="{'open': !(menuCollapsed | async)}" [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
  class="navbar navbar-expand-md navbar-container" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <div class="container">
      <div class="reset-padding-md">
          <div id="collapsingNav">
              <ul class="navbar-nav navbar-navigation mr-auto shadow-none">
                  <ng-container *ngFor="let section of (sections | async)">
                      <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
                  </ng-container>
              </ul>
          </div>
      </div>
      <div class="accesibility">
        <button (click)="toggleDarkMode()">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_18_143)">
            <rect width="38" height="38" fill="#000824"/>
            <path d="M19 29C24.52 29 29 24.52 29 19C29 13.48 24.52 9 19 9C13.48 9 9 13.48 9 19C9 24.52 13.48 29 19 29ZM20 11.07C23.94 11.56 27 14.92 27 19C27 23.08 23.95 26.44 20 26.93V11.07Z" fill="white"/>
            </g>
            <rect x="1" y="1" width="36" height="36" stroke="white" stroke-width="2"/>
            <defs>
            <clipPath id="clip0_18_143">
            <rect width="38" height="38" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </button>
        <button (click)="downFontSize()">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_18_145)">
            <rect width="38" height="38" fill="white"/>
            <rect width="38" height="38" fill="#000824"/>
            <path d="M13.4976 21.5H14.7076L15.3426 19.71H18.1676L18.7976 21.5H20.0076L17.3776 14.5H16.1276L13.4976 21.5ZM15.7076 18.695L16.7226 15.8H16.7826L17.7976 18.695H15.7076ZM24.5026 17.5V18.5H20.5026V17.5H24.5026Z" fill="white"/>
            <rect x="0.5" y="0.5" width="37" height="37" stroke="#0F69C4"/>
            </g>
            <rect x="1" y="1" width="36" height="36" stroke="white" stroke-width="2"/>
            <defs>
            <clipPath id="clip0_18_145">
            <rect width="38" height="38" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </button>
        <button (click)="upFontSize()">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_18_147)">
            <rect width="38" height="38" fill="white"/>
            <rect width="38" height="38" fill="#000824"/>
            <path d="M7.99512 26H10.4151L11.6851 22.42H17.3351L18.5951 26H21.0151L15.7551 12H13.2551L7.99512 26ZM12.4151 20.39L14.4451 14.6H14.5651L16.5951 20.39H12.4151ZM27.0051 18H30.0051V20H27.0051V23H25.0051V20H22.0051V18H25.0051V15H27.0051V18Z" fill="white"/>
            </g>
            <rect x="1" y="1" width="36" height="36" stroke="white" stroke-width="2"/>
            <defs>
            <clipPath id="clip0_18_147">
            <rect width="38" height="38" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </button>
      </div>
  </div>
</nav>

