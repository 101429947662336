import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Region } from '../interfaces/regions.interface';
import { IndexingService } from '../services/indexing.service';

@Component({
  selector: 'ds-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  filterZones: FormGroup;
  geographicCoverage: FormGroup;
  regions: any[];
  selectedRegions: Region[] = [];
  allProvincesSelected: any = {checked:false, disabled:false};
  allRegionsSelected: any = {checked:false, disabled:false};
  allCommunesSelected: any = {checked:false, disabled:false};
  @Output() notifyGeography = new EventEmitter<Region[]>();

  @Input() submissionId: number;

  constructor(private service: IndexingService) { }

  ngOnInit(): void {
    this.filterZones = new FormGroup({
      regions: new FormControl(''),
      provinces: new FormControl(''),
      communes: new FormControl('')
    });
    this.getDataRegionsService();
  }

  getDataRegionsService() {
    this.service.getDataRegions().subscribe(data => {
      this.regions = data;
    });
  }

  get coverages() {
    return this.geographicCoverage.get('coverages') as FormArray;
  }

  handleAllRegions(checked: boolean) {
    this.allRegionsSelected.checked = checked;
    this.service.setRegionsGlobal(checked);
  }

  handleAllProvinces(checked: boolean) {
    this.allProvincesSelected.checked = checked;
    this.allRegionsSelected.checked = true;
    this.allRegionsSelected.disabled = checked;
    this.service.setProvincesGlobal(checked);
  }

  handleAllCommunes(checked: boolean) {
    this.allCommunesSelected.checked = checked;
    if (checked){
      this.handleAllProvinces(checked);
    }
    this.allProvincesSelected.disabled = checked;
    this.service.setCommunesGlobal(checked);
  }

  onHandleChangeRegion(regions: Region[]) {
    this.notifyGeography.emit(regions);
  }

}