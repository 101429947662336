<div class="col-12">
    <div class="tools mt-4">
      <ul class="tools__items">
        <ds-bitstream [apiUrl]="dso._links.bundles.href"></ds-bitstream>
        <!--<ds-view-bitstreams></ds-view-bitstreams>-->
        <li class="tools__item">
          <a *ngIf="dso.allMetadata(['dc.contributor.author','dc.contributor.entity','dc.date.issued','dc.title','dc.relation.ispartofseries','dc.relation.ispartofvol','dc.relation.ispartofno','dc.identifier.uri']).length > 0"
            (click)="citaCreate(dso.id,dso.firstMetadataValue('dc.contributor.author'),dso.firstMetadataValue('dc.contributor.firmante'),dso.firstMetadataValue('dc.contributor.entity'),dso.firstMetadataValue('dc.date.issued'),dso.firstMetadataValue('dc.date.documento'),dso.firstMetadataValue('dc.title'),dso.firstMetadataValue('dc.title.documento'),dso.firstMetadataValue('dc.identifier.uri'))"
            (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" [attr.aria-controls]="dso.id"
             class="tools__action">
            <span class="tools__figure">
              <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.456543H2C0.9 0.456543 0.0100002 1.35654 0.0100002 2.45654L0 18.4565L7 15.4565L14 18.4565V2.45654C14 1.35654 13.1 0.456543 12 0.456543ZM12 15.4565L7 13.2765L2 15.4565V2.45654H12V15.4565Z" fill="currentColor"/>
              </svg>
            </span>
            <span class="tools__name tools__name_cita">Citar</span>
          </a>
        </li>
        <li class="tools__item">
          <a (click)="copyMessage(dso.firstMetadataValue('dc.identifier.uri'),'enlace','Enlace copiado exitosamente')" class="tools__action">
            <span class="tools__figure">
              <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 0.456543H11V2.45654H15C16.65 2.45654 18 3.80654 18 5.45654C18 7.10654 16.65 8.45654 15 8.45654H11V10.4565H15C17.76 10.4565 20 8.21654 20 5.45654C20 2.69654 17.76 0.456543 15 0.456543ZM9 8.45654H5C3.35 8.45654 2 7.10654 2 5.45654C2 3.80654 3.35 2.45654 5 2.45654H9V0.456543H5C2.24 0.456543 0 2.69654 0 5.45654C0 8.21654 2.24 10.4565 5 10.4565H9V8.45654ZM6 4.45654H14V6.45654H6V4.45654Z" fill="currentColor"/>
              </svg>
            </span>
            <span class="tools__name">Copiar enlace permanente</span>
          </a>
        </li>
        <!--<li class="tools__item">
          <a href="#" class="tools__action">
            <span class="tools__figure">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 14.12C14.24 14.12 13.56 14.42 13.04 14.89L5.91 10.74C5.96 10.51 6 10.28 6 10.04C6 9.80001 5.96 9.57001 5.91 9.34001L12.96 5.23001C13.5 5.73001 14.21 6.04001 15 6.04001C16.66 6.04001 18 4.70001 18 3.04001C18 1.38001 16.66 0.0400085 15 0.0400085C13.34 0.0400085 12 1.38001 12 3.04001C12 3.28001 12.04 3.51001 12.09 3.74001L5.04 7.85001C4.5 7.35001 3.79 7.04001 3 7.04001C1.34 7.04001 0 8.38001 0 10.04C0 11.7 1.34 13.04 3 13.04C3.79 13.04 4.5 12.73 5.04 12.23L12.16 16.39C12.11 16.6 12.08 16.82 12.08 17.04C12.08 18.65 13.39 19.96 15 19.96C16.61 19.96 17.92 18.65 17.92 17.04C17.92 15.43 16.61 14.12 15 14.12ZM15 2.04001C15.55 2.04001 16 2.49001 16 3.04001C16 3.59001 15.55 4.04001 15 4.04001C14.45 4.04001 14 3.59001 14 3.04001C14 2.49001 14.45 2.04001 15 2.04001ZM3 11.04C2.45 11.04 2 10.59 2 10.04C2 9.49001 2.45 9.04001 3 9.04001C3.55 9.04001 4 9.49001 4 10.04C4 10.59 3.55 11.04 3 11.04ZM15 18.06C14.45 18.06 14 17.61 14 17.06C14 16.51 14.45 16.06 15 16.06C15.55 16.06 16 16.51 16 17.06C16 17.61 15.55 18.06 15 18.06Z" fill="currentColor"/>
              </svg>
            </span>
            <span class="tools__name">Compartir</span>
          </a>
        </li>-->
      </ul>
    </div>
    <div #collapse="ngbCollapse" [ngbCollapse]="isCollapsed" id="{{dso.id}}">
      <br />
      <div id="cita--{{dso.id}}" contenteditable="true">
      </div>
      <br />
      <button (click)="copiarCita(dso.id, 'copiarcita')" class="btn btn-outline-primary"
        [style.display]="isVisible ? 'block' : 'none'">Copiar cita bibliográfica</button>
      <br />
    </div>
  
  </div>