<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="d-flex flex-wrap">
      <div>
        <h1 class="display-3">Biblioteca Digital del Gobierno de Chile</h1>
        <p class="lead">Acceda directamente a documentos y estudios desarrollados por cada ministerio.</p>
      </div>
    </div>
  </div>
</div>
