<div (click)="populateCollectionList(currentCollectionId, 1)">
    <div class="form-check-inline">
      <label class="form-check-label" for="radio1">
        <input *ngFor="let it of (searchListCollection$ | async)" (click)="onSelect(it,'General')" type="radio" class="form-check-input" id="radio1" name="optradio" [value]="'General'" [checked]="currentDefinition === 'General'"/>General
      </label>
    </div>
    <div class="form-check-inline">
      <label class="form-check-label" for="radio2">
        <input *ngFor="let it of (searchListCollection$ | async)" (click)="onSelect(it,'Estudios')" type="radio" class="form-check-input" id="radio2" name="optradio" [value]="'Estudios'" [checked]="currentDefinition === 'Estudios'">Estudios
      </label>
    </div>
      <span *ngIf="processing"><i class='fas fa-circle-notch fa-spin'></i></span>
</div>