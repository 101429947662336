import { Component, ElementRef, Injector, Renderer2 } from '@angular/core';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { MenuService } from '../shared/menu/menu.service';
import { HostWindowService } from '../shared/host-window.service';
import { BrowseService } from '../core/browse/browse.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationDataService } from '../core/data/feature-authorization/authorization-data.service';
import { MenuID } from '../shared/menu/menu-id.model';
import { ThemeService } from '../shared/theme-support/theme.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { isAuthenticated } from '../core/auth/selectors';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends MenuComponent {
  fontSize: number;
  scale = 5;
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated$: Observable<boolean>;

  public isXsOrSm$: Observable<boolean>;

  constructor(protected menuService: MenuService,
    protected injector: Injector,
              public windowService: HostWindowService,
              public browseService: BrowseService,
              public authorizationService: AuthorizationDataService,
              public route: ActivatedRoute,
              protected themeService: ThemeService,
              private store: Store<AppState>,
              private el: ElementRef,
              private renderer: Renderer2
  ) {
    super(menuService, injector, authorizationService, route, themeService);
    this.fontSize = this.getFontSize();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isXsOrSm$ = this.windowService.isXsOrSm();
    this.isAuthenticated$ = this.store.pipe(select(isAuthenticated));
  }
  getFontSize(): number {
    const computedStyle = window.getComputedStyle(document.documentElement);
    return parseInt(computedStyle.getPropertyValue('font-size'), 10) || 16;
  }

  toggleDarkMode() {
    const body = document.body;
    body.classList.toggle('dark-mode');
  }

  setFontSize(fontSize: number): void {
    this.renderer.setStyle(document.documentElement, 'font-size', fontSize + 'px');
    this.fontSize = fontSize;
  }


  upFontSize(): void {
    this.setFontSize(this.fontSize + 2);
  }
  downFontSize(): void {
    this.setFontSize(this.fontSize - 2);
  }

}
