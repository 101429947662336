import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormRadiobuttonService {

    private apiUrl = environment.rest?.baseUrl+'/api/custom/workspace/';

    private apiUrlCoverage = environment.rest?.baseUrl+'/api/custom/workspace/coverage/';

    private apiUrlViewCoverage = environment.rest?.baseUrl+'/api/custom/workspace/coverage/view';

  constructor(private http: HttpClient) { }

  getData(id: number, formSelect: string): Observable<any> {

    const dsAuthInfo = this.getCookieValue('dsAuthInfo').accessToken;
      console.log(dsAuthInfo);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${dsAuthInfo}`
    });

    const params = new HttpParams()
      .set('id', id.toString())
      .set('form_select', formSelect);

      

    return this.http.get(this.apiUrl, { headers, params });
  }

  updateCoverageData(id: number, data: any) {
    const dsAuthInfo = this.getCookieValue('dsAuthInfo').accessToken;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${dsAuthInfo}`
    });
  
    // Puedes crear un objeto con los datos que deseas enviar en el cuerpo
   
    const params = new HttpParams()
      .set('id', id);
    this.http.post(this.apiUrlCoverage, data, { headers, params }).subscribe(
      res => {
        //console.log(res);
      }
    );
  }

  getCoverageData(id: number):any {
    const dsAuthInfo = this.getCookieValue('dsAuthInfo').accessToken;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${dsAuthInfo}`
    });
  
    // Puedes crear un objeto con los datos que deseas enviar en el cuerpo
   
    const params = new HttpParams()
      .set('id', id);
    return this.http.get(this.apiUrlViewCoverage, { headers, params });
  }

  getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return JSON.parse(decodeURIComponent(cookie.substring(name.length, cookie.length)));
      }
    }
    return "";
  }
  
  
  
}
