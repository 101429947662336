import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'; 


@Injectable({
  providedIn: 'root'
})
export class ItemSearchResultListElementService {

  constructor(private http: HttpClient) { }

  getBitstreams(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map((response: any) => {
        const bundles = response._embedded.bundles;
        const originalBundle = bundles.find(bundle => bundle.name === 'ORIGINAL');

        if (originalBundle) {
          return originalBundle._links.bitstreams.href;
        } else {
          return null; // No se encontró el bundle, asignamos null como valor predeterminado
        }
      }),
      catchError((error: any) => {
        return throwError('Error al obtener los bitstreams: ' + error.message);
      })
    );
  }

  getBundleBitstreams(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map((response: any) => {
        return response._embedded.bitstreams[0].id;
      }),
      catchError((error: any) => {
        return throwError('Error al obtener los bitstreams del bundle: ' + error.message);
      })
    );
  }


}
