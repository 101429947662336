import { Component, Input } from '@angular/core';
import { ItemSearchResultListElementService } from '../../item-search-result-list-element.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ds-bitstream',
  templateUrl: './bitstream.component.html',
  styleUrls: ['./bitstream.component.scss']
})
export class BitstreamComponent {

  @Input() apiUrl: string;

  bitstreamsLink: string;
  protected bitstreams: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private itemSearchResultListElementService: ItemSearchResultListElementService) { }

  ngOnInit() {
    if (this.apiUrl) {
      this.itemSearchResultListElementService.getBitstreams(this.apiUrl).subscribe(
        bitstreamsLink => {
          this.bitstreamsLink = bitstreamsLink;

          if (bitstreamsLink) {
            this.itemSearchResultListElementService.getBundleBitstreams(bitstreamsLink).subscribe(
              bitstreams => {
                this.bitstreams.next("/bitstreams/"+bitstreams+"/download");
              },
              error => {
                console.error(error);
              }
            );
          }
        },
        error => {
          console.error(error);
        }
      );      
    }
   
  }


}
