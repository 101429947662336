import { Component, Input } from '@angular/core';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

@Component({
  selector: 'ds-accesos-directos',
  templateUrl: './accesos-directos.component.html',
  styleUrls: ['./accesos-directos.component.scss']
})
export class AccesosDirectosComponent {

  constructor(private notificationsService: NotificationsService){}

  @Input() dso;

  @Input() isCollapsed;

  isVisible = false;

  //Copiar enlace
  copyMessage(val: string, id, message:string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationsService.success(message);
    //this.isMessageCopied = true;
  }

  //Crear cita
  citaCreate(id, author, firmante, entity, date, dDocument, title, tDocument, uri) {

    if (entity == null) {
      entity = '';
    }
    if (date == null) {
      date = '';
    } else {
      date = '(' + date + ')';
    }

    if (author == null && firmante == null) {
      firmante = '';
      author = '';
    } else if (firmante != null) {
      author = firmante;
    }

    if (title == null && tDocument == null) {
      title = '';
      tDocument = '';
    } else if (tDocument != null) {
      title = tDocument + '.';
    } else if (title != null) {
      title = title + '.';
    }


    if (dDocument == null) {
      dDocument = '';
    } else if (dDocument != null) {
      date = '(' + dDocument + ')';
    }

    document.getElementById("cita--" + id).innerHTML = '';
    document.getElementById("cita--" + id).innerHTML += '<div id="cita.' + id + '" contenteditable class="cita" >' + author + ' ' + date + ' ' + title + ' ' + uri + '<div>';
    this.isVisible = true;
  }


  //copiarCita
  copiarCita(id, id_element) {
    const cita = document.getElementById("cita." + id).textContent;
    this.copyMessage(cita, id_element, "Cita bibliográfica se copió al portapapeles");
  }

}
