import { Component } from '@angular/core';

@Component({
  selector: 'ds-view-bitstreams',
  templateUrl: './view-bitstreams.component.html',
  styleUrls: ['./view-bitstreams.component.scss']
})
export class ViewBitstreamsComponent {

}
