<div class="row">
    <div class="col-12 mb-3">
      <ds-autocomplete></ds-autocomplete>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="border p-3">
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center mb-5">
              <div class="custom-control custom-checkbox mr-4">
                <input type="checkbox" class="custom-control-input" id="todaslasregiones" [checked]="allRegionsSelected.checked" [disabled]="allRegionsSelected.disabled" (change)="handleAllRegions($event.target.checked)">
                <label class="custom-control-label" for="todaslasregiones">Todas las Regiones</label>
              </div>
              <div class="custom-control custom-checkbox mr-4">
                <input type="checkbox" class="custom-control-input" id="todaslasprovincias" [checked]="allProvincesSelected.checked" [disabled]="allProvincesSelected.disabled" (change)="handleAllProvinces($event.target.checked)">
                <label class="custom-control-label" for="todaslasprovincias">Todas la Provincias</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="todaslascomunas" [checked]="allCommunesSelected.checked" [disabled]="allCommunesSelected.disabled" (change)="handleAllCommunes($event.target.checked)">
                <label class="custom-control-label" for="todaslascomunas">Todas las Comunas</label>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="regions">
            <ds-region class="row" [region]="regions" (notifyCountry)="onHandleChangeRegion($event)" [submissionId]="submissionId"></ds-region>
          </div>
        </div>
      </div>
    </div>
  </div>
  