<div [formGroup]="geography" (change)="onGeographyChanged()">
    <div class="row">
      <!--<div class="col-12 mb-3">
        <label>País</label>
        <input type="text" class="form-control" placeholder=" " formControlName="country">
      </div>-->
      <!-- gestión regional -->
      <ds-country class="col-12 mb-3" (notifyGeography)="onHandleChangeCountry($event)" [submissionId]="submissionId"></ds-country>
      <!-- end gestión regional -->
      <!--<div class="col-12 mb-3">
        <label>Otros paises</label>
        <select class="custom-select" multiple formControlName="countrys">
          <option *ngFor="let option of countries" [value]="option.nombre">{{option.nombre}}</option>
        </select>
      </div>-->
    </div>
  </div>
  