<ng-container *ngFor="let region of dataRegions; let i = index;">
    <div class="col-12 col-md-6 mb-3">
      <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
        <ngb-panel id="custom-panel-0">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="accordion-button custom-header justify-content-between" [class.collapsed]="!opened">
                <button ngbPanelToggle class="btn-header-accordion">
                  <i class="fas fa-chevron-down"></i>
                  <span>{{region.nombre}}</span>
                </button>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="rgn{{region.id}}" [checked]="region.checked" [disabled]="region.disabled" (change)="handleChangeRegion($event, i)">
                  <label class="custom-control-label" for="rgn{{region.id}}">Seleccionar</label>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ds-province [data_from_dspace]="data_from_dspace" [region]="region" [province]="region.provincia" (listenProvince)="onHandleChangeProvince($event, i)"></ds-province>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
    </div>
  </ng-container>

