import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Region } from '../interfaces/regions.interface';
import { IndexingService } from '../services/indexing.service';

@Component({
  selector: 'ds-commune',
  templateUrl: './commune.component.html',
  styleUrls: ['./commune.component.scss']
})
export class CommuneComponent implements OnInit, OnDestroy {
  regiones: Region[];
  private onDestroy$ = new Subject<void>();
  dataProvince: any;
  dataCommunes: any[];
  selectedCommunes: string[] = [];
  selectAllCommunes: any;
  @Input() region: any;
  private _province: any;
  @Input() set province(value: any) {
    this._province = value;
  }

  @Input() data_from_dspace:any;

  get province(): any {
    return this._province;
  }
  private _commune: any;
  @Input() set commune(value: any) {
    this._commune = value;
  }

  get commune(): any {
    return this._commune;
  }

  @Output() listenCommunes = new EventEmitter<string[]>();

  constructor(private service: IndexingService) {
    this.service.getCommunesGlobal()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.onselectAllCommunesChange(data);
    });

    this.service.getSearchPlace()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dynamicCommunesChange(data);
    });
  }

  ngOnInit(): void {
    this.dataCommunes = this.commune.map(item => {
      const nuevoItem = {
        nombre: item.nombre,
        checked: false,
        id: item.nombre.replace(/[^a-z0-9]+/gi, '')
      };

      this.data_from_dspace.forEach(it => {
        for (const clave in it) {
          if (it.hasOwnProperty(clave)) {
            const valor = it[clave];
            if(valor.comunas != undefined) {
              for (let i = 0; i < valor.comunas.length; i++) {
                const element = valor.comunas[i];
                if(item.nombre == element) {
                  
                  nuevoItem.checked = true;

                  //this.selectedCommunes.push(element);
                  //this.listenCommunes.emit(this.selectedCommunes);

                  if (this.selectedCommunes.includes(item.nombre)) {
                    let index = this.selectedCommunes.indexOf(item.nombre);
                    this.selectedCommunes.splice(index, 1);
                  } else {
                    this.selectedCommunes.push(item.nombre);
                  }
              
                  const selectedCommunes = this.dataCommunes.filter(c => c.checked);
                  const allCommunesSelected = selectedCommunes.length === this.dataCommunes.length;
              
                  this.dataProvince.checked = allCommunesSelected;
                  this.listenCommunes.emit(this.selectedCommunes);

                }                
              }              
            }
            
          }
        }
        
      })

      return nuevoItem;
      
    });
    this.dataProvince = {id: this.province.id, name:this.province.nombre, disabled:false, checked:false};



  }

  handleChangeCommune(event: any, i: number) {
    const commune = this.dataCommunes[i];
    this.dataCommunes[i].checked = event.target.checked;

    if (this.selectedCommunes.includes(commune.nombre)) {
      let index = this.selectedCommunes.indexOf(commune.nombre);
      this.selectedCommunes.splice(index, 1);
    } else {
      this.selectedCommunes.push(commune.nombre);
    }

    const selectedCommunes = this.dataCommunes.filter(c => c.checked);
    const allCommunesSelected = selectedCommunes.length === this.dataCommunes.length;

    this.dataProvince.checked = allCommunesSelected;
    this.listenCommunes.emit(this.selectedCommunes);
  }

  checkAllCommunes(checked: boolean) {
    this.dataProvince.checked = checked;
    this.dataCommunes.forEach(commune => commune.checked = checked);
    if  (checked) {
      this.selectedCommunes = this.dataCommunes.filter(commune => commune.checked).map(commune => commune.nombre);
    } else {
      this.selectedCommunes = [];
    }
    this.listenCommunes.emit(this.selectedCommunes);
  }

  onselectAllCommunesChange(checked: boolean){
    if (checked !== null){
      this.checkAllCommunes(checked);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  dynamicCommunesChange(data) {
    if (data !== null){
      if (data.comuna !== null){
        const matchingElement = this.dataCommunes.find(p => p.nombre === data.comuna);
        if (matchingElement){
          matchingElement.checked = true;
          this.selectedCommunes.push(data.comuna);
          this.listenCommunes.emit(this.selectedCommunes);
        }
      }
    }
  }
}

