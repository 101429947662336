
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Province } from '../interfaces/regions.interface';
import { IndexingService } from '../services/indexing.service';

@Component({
  selector: 'ds-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.scss']
})
export class ProvinceComponent implements OnInit, OnDestroy {
  dataProvinces: any[];
  selectedProvinces: Province[] = [];
  allProvinceSelected: any = {checked:false, disabled:false};
  private onDestroy$ = new Subject<void>();
  @Input() region: any;
  private _province: any;
  @Input() set province(value: any) {
    this._province = value;
  }

  get province(): any {
    return this._province;
  }

  private _selectAllProvinces: boolean;
  @Input() set selectAllProvinces(value: boolean) {
    this._selectAllProvinces = value;
    this.onselectAllProvincesChange(value);
  }
  get selectAllProvinces(): boolean {
    return this._selectAllProvinces;
  }

  @Output() listenProvince = new EventEmitter<Province[]>();
  @Output() notifySelectAllProvinces = new EventEmitter<boolean>();

  @Input() data_from_dspace: any;

  constructor(private service: IndexingService) {
    this.service.getProvincesGlobal()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.onselectAllProvincesChange(data);
      });

    this.service.getSearchPlace()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dynamicProvincesChange(data);
    });
  }
  public check: boolean = false;
  ngOnInit(): void {
    this.dataProvinces = this.province.map(it => {
      const nuevoItem = {
        checked: false,
        disabled: false
      };
      this.data_from_dspace.forEach((item) => {
        for (const clave in item) {
          if (item.hasOwnProperty(clave)) {
            const valor = item[clave];
            if(valor.provincias != undefined) {
              for (let i = 0; i < valor.provincias.length; i++) {
                const element = valor.provincias[i];
                if(it.nombre == element) {
                  nuevoItem.checked = true;
                  this.check = nuevoItem.checked;
                  this.selectedProvinces.push({
                    id: it.id,
                    name: it.nombre,
                    communes: []
                  });
                  const selectedProvinces = this.dataProvinces.filter(p => p.checked);
                  const allCommunesSelected = selectedProvinces.length === this.dataProvinces.length;

                  this.allProvinceSelected.checked = allCommunesSelected;
                  this.allProvinceSelected.disabled = allCommunesSelected;
                  this.listenProvince.emit(this.selectedProvinces);
                }                
              }
            }          
          }
        }
      });

      for (const clave in it) {
        if (it.hasOwnProperty(clave)) {
          nuevoItem[clave] = it[clave];
          
        }
      }
    
      return nuevoItem;
    });
  }

  onHandleChangeCommune(communes: string[], index: number) {
    this.dataProvinces[index].checked = true;
    this.dataProvinces[index].disabled = communes.length > 0;
    const province = this.selectedProvinces.find(p => p.id === this.dataProvinces[index].id);
    if (!province) {
      this.selectedProvinces.push({
        id: this.dataProvinces[index].id,
        name: this.dataProvinces[index].nombre,
        communes
      });
    } else {
      if(this.selectedProvinces[index] != undefined) {
      this.selectedProvinces[index].communes = communes;
      }
    }
    const hasCommunes = this.selectedProvinces.some(p => p.communes.length > 0);
    this.allProvinceSelected.disabled =  this.allProvinceSelected.checked ? hasCommunes : false;
    this.listenProvince.emit(this.selectedProvinces);
  }

  checkProvince(event: any, index: number) {
    this.dataProvinces[index].checked = event.target.checked;
    if (this.dataProvinces[index].checked){
      const provincia = this.selectedProvinces.find(p => p.id === this.dataProvinces[index].id);
      if (!provincia) {
        this.selectedProvinces.push({
          id: this.dataProvinces[index].id,
          name: this.dataProvinces[index].nombre,
          communes: []
        });
      }
    } else {
      const indexOfProvincia = this.selectedProvinces.findIndex((object) => {return object.id === this.dataProvinces[index].id;});
      if (indexOfProvincia !== -1) {
        this.selectedProvinces.splice(indexOfProvincia, 1);
      }
    }

    const selectedProvinces = this.dataProvinces.filter(p => p.checked);
    const allCommunesSelected = selectedProvinces.length === this.dataProvinces.length;

    this.allProvinceSelected.checked = allCommunesSelected;
    this.allProvinceSelected.disabled = allCommunesSelected;
    this.listenProvince.emit(this.selectedProvinces);
  }

  checkAllProvinces(checked: boolean) {
    this.allProvinceSelected.checked = checked;
    const communes = this.selectedProvinces.filter(province => province.communes.length).map(province => {
      return {id: province.id, communes: province.communes};
    });
    this.allProvinceSelected.disabled = communes.length > 0 ? true : false;
    this.dataProvinces.forEach(province => {
      if (!province.disabled) {
        province.checked = checked;
      }
    });
    if  (checked) {
      this.selectedProvinces = this.dataProvinces.filter(province => province.checked).map(province => {
        const currentCommunes = communes.find(item => province.id === item.id);
        return {id: province.id, name: province.nombre, communes: currentCommunes ? currentCommunes.communes : []};
      });
    } else {
      this.selectedProvinces = [];
    }
    this.listenProvince.emit(this.selectedProvinces);
  }

  onselectAllProvincesChange(checked: boolean) {
    if (checked !== null){
      this.checkAllProvinces(checked);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  dynamicProvincesChange(data) {
    if (data !== null){
      if (data.provincia !== null){
        const matchingElement = this.dataProvinces.find(p => p.id === data.province.id);
        if (matchingElement){
          matchingElement.checked = true;
          this.selectedProvinces.push({
            id: matchingElement.id,
            name: matchingElement.nombre,
            communes: []
          });
          this.listenProvince.emit(this.selectedProvinces);
        }
      }
    }
  }

}
