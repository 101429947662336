import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  public url;

  urlToExternalProvider(url: string) {
    this.url = url;
    return url;
  };

}
