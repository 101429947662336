<div class="row">
  <div class="col-12 d-flex mb-3 gap-3">
    <ds-themed-badges *ngIf="showLabel" [object]="dso" [context]="context" [showAccessStatus]="true"></ds-themed-badges>
  </div>
  <div *ngIf="showThumbnails" class="col-3 col-md-2">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
       [routerLink]="[itemPageRoute]" class="dont-break-out">
    <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="dont-break-out">
    <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
    </span>
  </div>
  <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">
    <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out" [innerHTML]="dsoTitle"></a>
      <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out" [innerHTML]="dsoTitle"></span>
      <span class="text-muted"  >
        <ds-truncatable-part [id]="dso.id" [minLines]="1">
          <div class="d-flex" *ngIf="dso.allMetadata(['dc.contributor.author']).length > 0">
            <span class="text-default text-nowrap">Autor:&nbsp;</span>
            <span class="item-list-authors">
              <span *ngFor="let author of allMetadataValues(['dc.contributor.author']); let last=last;">
                <a [routerLink]="['/search']" [queryParams]="{'f.author': author+',equals'}">
                  <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                  <span *ngIf="!last">; </span>
                </a>
              </span>
            </span>
          </div>
          <div class="d-flex" *ngIf="dso.allMetadata(['dc.contributor.entity']).length > 0">
            <span class="text-default text-nowrap">Autor institucional:&nbsp;</span>
            <span class="item-list-authors">
              <span *ngFor="let entity of allMetadataValues(['dc.contributor.entity']); let last=last;">
                <a [routerLink]="['/search']" [queryParams]="{'f.entity': entity+',equals'}">
                  <span [innerHTML]="entity"><span [innerHTML]="entity"></span></span>
                  <span *ngIf="!last">; </span>
                </a>
              </span>
            </span>
          </div>
          <div class="d-flex" *ngIf="dso.allMetadata(['dc.source.ministerio']).length > 0">
            <span class="text-default text-nowrap">Ministerios:&nbsp;</span>
            <span class="item-list-authors">
              <span *ngFor="let ministerio of allMetadataValues(['dc.source.ministerio']); let last=last;">
                <a [routerLink]="['/search']" [queryParams]="{'f.ministerio': ministerio+',equals'}">
                  <span [innerHTML]="ministerio"><span [innerHTML]="ministerio"></span></span>
                  <span *ngIf="!last">; </span>
                </a>
              </span>
            </span>
          </div>
          <div class="d-flex" *ngIf="dso.allMetadata(['dc.category.document']).length > 0">
            <span class="text-default text-nowrap">Tipo de estudio:&nbsp;</span>
            <span class="item-list-authors">
              <span *ngFor="let category of allMetadataValues(['dc.category.document']); let last=last;">
                <a [routerLink]="['/search']" [queryParams]="{'f.category': category+',equals'}">
                  <span [innerHTML]="category"><span [innerHTML]="category"></span></span>
                  <span *ngIf="!last">; </span>
                </a>
              </span>
            </span>
          </div>
          <div class="d-flex" *ngIf="dso.allMetadata(['dc.date.issued']).length > 0">
            <span class="text-default text-nowrap">Fecha de publicación:&nbsp;</span>
            <span class="item-list-authors">
              <span *ngFor="let dateIssued of allMetadataValues(['dc.date.issued']); let last=last;">
                <a [routerLink]="['/search']" [queryParams]="{'f.dateIssued.min': dateIssued,'f.dateIssued.max': dateIssued}">
                  <span [innerHTML]="dateIssued"><span [innerHTML]="dateIssued"></span></span>
                  <span *ngIf="!last">; </span>
                </a>
              </span>
            </span>
          </div>
        </ds-truncatable-part>
      </span>
      <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
        <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
          [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
        </ds-truncatable-part>
      </div>
    </ds-truncatable>
    <div *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')" class="mt-3">
      <span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher" [innerHTML]="firstMetadataValue('dc.publisher')"></span>
      <span *ngIf="dso.firstMetadataValue('dc.publisher') && dso.firstMetadataValue('dc.date.issued')">, </span>
      <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>
    </div>
  </div>
  <ds-accesos-directos [dso]="dso" [isCollapsed]="isCollapsed"></ds-accesos-directos>
</div>
