import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, flatMap } from 'rxjs';
import { Region } from '../interfaces/regions.interface';


@Injectable({
  providedIn: 'root'
})
export class IndexingService {

  private controlRegionsGlobal = new BehaviorSubject<any>(null);
  private controlProvincesGlobal = new BehaviorSubject<any>(null);
  private controlCommunesGlobal = new BehaviorSubject<any>(null);
  private searchPlace = new BehaviorSubject<any>(null);
  private country: Region[];

  constructor(private http: HttpClient) { }

  getSearchPlace() {
    return this.searchPlace.asObservable();
  }
  setSearchPlace(data: any): void {
    this.searchPlace.next(data);
  }

  getRegionsGlobal() {
    return this.controlRegionsGlobal.asObservable();
  }
  setRegionsGlobal(checked: boolean): void {
    this.controlRegionsGlobal.next(checked);
  }
  getCommunesGlobal() {
    return this.controlCommunesGlobal.asObservable();
  }
  setCommunesGlobal(checked: boolean): void {
    this.controlCommunesGlobal.next(checked);
  }

  getProvincesGlobal() {
    return this.controlProvincesGlobal.asObservable();
  }

  setProvincesGlobal(checked: boolean): void {
    this.controlProvincesGlobal.next(checked);
  }

  getDataRegions(): Observable<any> {
    return this.http.get('assets/estructura.json');
  }

  getDataCountries(): Observable<any> {
    return this.http.get('assets/paises.json');
  }

  getDataCollections(): Observable<any> {
    return this.http.get('assets/communities.json');
  }

  getDataPrograms(): Observable<any> {
    return this.http.get('assets/programas.json');
  }

  getDataThematicCategory(): Observable<any> {
    return this.http.get('assets/categoria_tematica.json');
  }

  getDataKeyWords(): Observable<any> {
    return this.http.get('assets/claves.json');
  }

  getDataOdsFull(): Observable<any> {
    return this.http.get('assets/ods_full.json');
  }

  getDataRegionalManagement(): Observable<any> {
    return this.http.get('assets/direcciones_regionales.json');
  }

  getDataDocumentType(): Observable<any> {
    return this.http.get('assets/tipo_documento.json');
  }

  getDataLanguages(): Observable<any> {
    return this.http.get('assets/idiomas.json');
  }

  getDataFormats(): Observable<any> {
    return this.http.get('assets/formatos.json');
  }

  procesarCountry(country: Region[]): void {
    this.country = country;
  }

  getCountry() {
    if(this.country === undefined || this.country === null) {
      return false;
    } else {
      let lista:any[] = [];
      let listaFormateada: { [key: string]: string[] } = {};
      let regionNames: string[] = this.country.map(region => region.name);
      let provinceNames: string[] = this.country.reduce((acc, region) =>
      acc.concat(region.provinces?.map(province => province.name) || []), [] as string[]);
      let communeNames: string[] = this.country.reduce((acc, region) =>
      acc.concat(region.provinces?.reduce((provAcc, province) =>
      provAcc.concat(province.communes?.map(commune => commune) || []), [] as string[]) || []), [] as string[]);

      listaFormateada = {
        regiones: regionNames,
        provincias: provinceNames,
        comunas: communeNames
      };

      lista.push(listaFormateada);
      return lista;
    }    
  }

}
