<header>
    <div class="container">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <a class="navbar-brand" routerLink="/home">
          <img src="assets/dgdbd/images/logo-gobdigital.svg" [attr.alt]="'menu.header.image.logo' | translate">
        </a>        
        <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
          <!--<ds-themed-search-navbar></ds-themed-search-navbar>
          <ds-lang-switch></ds-lang-switch>-->
          <ds-context-help-toggle></ds-context-help-toggle>
          <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
          <ds-impersonate-navbar></ds-impersonate-navbar>
          <div class="pl-2">
            <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                    aria-controls="collapsingNav"
                    aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
              <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
            </button>
          </div>
        </nav>
      </div>
    </div>
  </header>
