<div class="row">
    <div class="col-12 mb-1">
      <h4 class="lh-1 mb-2">Comunas</h4>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="allc{{dataProvince.id}}" [checked]="dataProvince.checked" (change)="checkAllCommunes($event.target.checked)">
        <label class="custom-control-label" for="allc{{dataProvince.id}}">Todas las comunas de esta provincia</label>
        <hr class="mt-2"/>
      </div>
    </div>
    <div class="col-12 mb-3" *ngFor="let commune of dataCommunes; let i = index">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" [checked]="commune.checked" class="custom-control-input" id="{{commune.id}}" (change)="handleChangeCommune($event, i)">
        <label class="custom-control-label" for="{{commune.id}}">{{commune.nombre}}</label>
      </div>
    </div>
  </div>
  
  