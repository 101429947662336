import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Region } from './interfaces/regions.interface';
import { IndexingService } from './services/indexing.service';

@Component({
  selector: 'ds-geography',
  templateUrl: './geography.component.html',
  styleUrls: ['./geography.component.scss']
})
export class GeographyComponent implements OnInit {
  geography: FormGroup;
  countries: any[];
  selectedCountries: Region[];

  @Output() geographicCoverageChange = new EventEmitter<FormGroup>();

  @Input() submissionId: number;

  constructor(private fb: FormBuilder, private service: IndexingService) {}

  ngOnInit(): void {
    this.getDataRegionsService();
    this.geography = new FormGroup({
      country: new FormControl({value: 'Chile', disabled: true}),
      countrys: new FormControl([]),
      coverage: this.fb.array([])
    });
  }
  get coverage() {
    return this.geography.get('coverage') as FormArray;
  }
  get countrys() {
    return this.geography.get('countrys').value;
  }

  getDataRegionsService() {
    this.service.getDataCountries().subscribe(data => {
      this.countries = data;
    });
  }

  onHandleChangeCountry(country: Region[]) {
    const coverageFormArray = this.geography.get('coverage') as FormArray;
    while (coverageFormArray.length !== 0) {
      coverageFormArray.removeAt(0);
    }
    country.forEach(() => {
      coverageFormArray.push(new FormControl(''));
    });
    coverageFormArray.setValue(country);
    this.service.procesarCountry(country);
  }

  onGeographyChanged() {
    this.geographicCoverageChange.emit(this.geography);
  }

}
